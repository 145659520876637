<template>
  <div>
    <div class="text-center mb-5">
      <h1 class="mb-5">
        <strong>{{ settings.logo }}</strong>
      </h1>
      <p>
        {{ title === '' ? 'Heasoft ' + settings.whatapp.appName + ' system.' : title }}
        <br />
        <!-- <strong v-if="title === ''">version code: </strong>{{ title === '' ? versionCode : 'License by Heasoft' }}<br /> -->
        <strong>version code: </strong>{{ versionCode }}<br />
        <!-- <strong v-if="title === '' && appname !== ''">Current App:</strong>{{ title === '' ? settings.whatapp.appName === 'SLIK' ? settings.whatapp.appName + ' System' : settings.whatapp.appName : 'Terms of Use | Privacy Policy' }} -->
        Terms of Use | Privacy Policy
      </p>
    </div>
    <div class="card" :class="$style.container">
      <div class="text-dark font-size-24 mb-3">
        <strong>Sign in to your account</strong>
      </div>
      <div class="mb-4" hidden>
        <a-radio-group
          :value="settings.authProvider"
          @change="e => changeAuthProvider(e.target.value)"
        >
          <a-radio value="firebase">Firebase</a-radio>
          <a-radio value="jwt">JWT</a-radio>
          <a-tooltip>
            <template slot="title">
              <span>Read Docs Guide</span>
            </template>
            <a-radio value="auth0" disabled>Auth0</a-radio>
          </a-tooltip>
          <a-tooltip>
            <template slot="title">
              <span>Read Docs Guide</span>
            </template>
            <a-radio value="strapi" disabled>Strapi</a-radio>
          </a-tooltip>
        </a-radio-group>
      </div>
      <a-form class="mb-4" :form="form" @submit="handleSubmit">
        <a-form-item style="margin-bottom: 0px;">
          <a-input
            size="large"
            placeholder="Username"
            v-decorator="['username', { initialValue: '', rules: [{ required: true, message: 'Please input your username!' }]}]"
          />
        </a-form-item>
        <a-form-item style="margin-bottom: 10px;">
          <a-input
            size="large"
            placeholder="Password"
            type="password"
            v-decorator="['password', {initialValue: '', rules: [{ required: true, message: 'Please input your Password!' }]}]"
          />
        </a-form-item>
          <!-- size="small" -->
        <!-- <a-dropdown-button :disabled="loading" v-if="indexedDB !== null && indexedDB !== undefined && indexedDB.length !== 0" @click="loginMode = 'netral'" type="primary" htmlType="submit" class="text-center mr-2">
          Sign in
          <a-menu slot="overlay">
            <a-menu-item key="1" class="text-center text-primary" @click="freshloginclick"> <a-icon type="redo" />Fresh Login </a-menu-item>
          </a-menu>
        </a-dropdown-button> -->
        <center>
          <a-button
            :loading="loading"
            style="width: 50%;"
            ref="freshloginslurr"
            type="primary"
            htmlType="submit"
            class="text-center mr-2"
            @click="loginMode = 'fresh'"
          >
            <!-- :style="indexedDB === null || indexedDB === undefined || indexedDB.length === 0 ? '' : 'display: none;'" -->
            <strong>Login</strong>
          </a-button>
        </center>
      </a-form>
      <label class="text-primary kit__utils__link font-size-16" style="cursor: pointer;" @click="visible = !visible">Forgot Password?</label>
      <!-- <router-link to="/auth/forgot-password" class="kit__utils__link font-size-16">Forgot Password?</router-link> -->
      <label class="text-primary" style="cursor: pointer;" @click="resetAllStorage">Click here if login take too much time!</label>
    </div>
    <div class="text-center pt-2 mb-auto">
      <span class="mr-2">Don't have an account?
        <!-- <router-link to="/auth/register" class="kit__utils__link font-size-16">Register</router-link> -->
        </span>
      <router-link to="#" class="kit__utils__link font-size-16">Get out</router-link>
    </div>
    <a-modal v-model="visible" title="Forgot Password?" :dialogStyle="{ top: '10px' }">
      <template slot="footer">
        <a-button class="btn btn-outline-dark" @click="visible = false">
          Thanks!
        </a-button>
      </template>
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <label>Try to calm, relax and try to remember your password!</label>
        </div>
      </div>
    </a-modal>
  </div>
</template>
<script>
import * as lou from '@/services/data/lou'
import { mapState } from 'vuex'

export default {
  name: 'CuiLogin',
  computed: {
    ...mapState(['settings']),
    loading() {
      return this.$store.state.user.loading
    },
  },
  mounted () {
    this.checkDomain()
  },
  created () {
    // console.log('navigator.doNotTrack', navigator.doNotTrack)
    // console.log('this.settings.whatapp', this.settings)
    this.appname = this.settings.whatapp !== null && this.settings.whatapp !== '[object Object]' ? this.settings.whatapp : ''
    this.getIndexedDb()
  },
  data: function () {
    return {
      licencedby: '',
      login: 'fresh',
      title: '',
      appname: '',
      form: this.$form.createForm(this),
      versionCode: this.$versionCode,
      currentDate: new Date(),
      indexedDB: null,
      visible: false,

      domainList: [],
    }
  },
  methods: {
    resetAllStorage() {
      lou.dropDb()
      localStorage.clear()
      location.reload()
    },
    async checkDomain() {
      var res = await lou.customUrlGet2('start/appnames', false, true)
      if (res) {
        this.domainList = res.data
        this.thencheckDomain()
      }
      // console.log('location.host 1', location.host)
      // if (location.host === 'kop.heasoft.com') {
      //   this.title = 'Koperasi System'
      //   this.settings.logo = 'Koperasi System'
      // }
      // if (location.host.includes('dreo.my.id')) {
      //   this.title = 'DREO System'
      //   this.settings.logo = 'DREO System'
      //   this.licencedby = 'DREO'
      //   this.$router.push({ path: '/auth/blockeddomain' })
      // }
    },
    thencheckDomain() {
      var targetIndx = this.domainList.findIndex(x => location.host.includes(x.url))
      if (targetIndx > -1) {
        var targetDomain = this.domainList[targetIndx]
        this.title = targetDomain.system_name
        this.settings.logo = targetDomain.system_name
        this.licencedby = targetDomain.copyright
        this.settings.companyname = targetDomain.company_name
        this.settings.licencedby = targetDomain.copyright
        if (targetDomain.status === 0) {
          this.$router.push({ path: '/auth/blockeddomain' })
        }
      } else {
        if (location.host.includes('localhost')) {
          this.title = 'Heasoft Banking System'
          this.settings.logo = 'Heasoft Banking System'
          this.settings.companyname = 'Heasoft Indonesia'
          this.settings.licencedby = 'PT Heasoft Indonesia Eka'
          this.licencedby = 'Developer Mode'
          lou.shownotif('Information!', 'Developing mode activated.')
        } else {
          this.$router.push({ path: '/auth/blockeddomain' })
        }
      }
      // console.log('targetIndx', targetIndx)
    },
    freshloginclick() {
      // console.log('this.$refs.freshloginslurr', this.$refs.freshloginslurr)
      this.$refs.freshloginslurr.$el.click()
    },
    async getIndexedDb() {
      var nindexeddb = await lou.getDataFromDb()
      this.indexedDB = nindexeddb
      // console.log('this.indexedDB', this.indexedDB)
    },
    changeAuthProvider(value) {
      this.$store.commit('CHANGE_SETTING', { setting: 'authProvider', value })
    },
    showYourIp() {
      fetch('https://api.ipify.org?format=json')
        .then(x => x.json())
        .then(({ ip }) => {
          this.term = ip
        }).then(() => {
          // console.log('this.term', this.term)
        })
    },
    handleSubmit(e) {
      e.preventDefault()
      this.form.validateFields(async (err, values) => {
        if (!err) {
          // var valu = {
          //   ...values,
          //   ip: 'blocked',
          // }
          // if (this.loginMode === 'fresh') {
          //   this.$store.dispatch('user/FRESHLOGIN', { payload: valu })
          // } else {
          //   this.$store.dispatch('user/LOGIN', { payload: valu })
          // }
          // var res = await lou.ipReqTesting(true)
          fetch('https://fwdbaharibms.lb-bpr.id/jsonip')
            .then(response => response.json())
            .then(({ ip }) => {
              // console.log('ip', ip)
              this.term = ip
            }).then(() => {
              // console.log('this.term', this.term)
              var valu = {
                ...values,
                ip: this.term,
              }
              if (this.loginMode === 'fresh') {
                this.settings.currentIp = this.term
                this.settings.activeSessions = this.term
                this.$store.dispatch('user/FRESHLOGIN', { payload: valu })
              } else {
                this.settings.currentIp = this.term
                this.settings.activeSessions = this.term
                this.$store.dispatch('user/LOGIN', { payload: valu })
              }
            }).catch((error) => {
              console.log('error', error)
              var valu = {
                ...values,
                ip: 'blocked',
              }
              if (this.loginMode === 'fresh') {
                this.$store.dispatch('user/FRESHLOGIN', { payload: valu })
              } else {
                this.$store.dispatch('user/LOGIN', { payload: valu })
              }
            })
          // var nindexeddb = await lou.getDataFromDb()
          // console.log('nindexeddb', nindexeddb)
          // if (nindexeddb !== undefined || nindexeddb !== null) {
          // if (nindexeddb.length !== 0) {
          // } else {
          // }
          // } else {
          // this.$store.dispatch('user/FRESHLOGIN', { payload: values })
          // }
        }
      })
    },
  },
}
</script>
<style lang="scss" module>
@import "@/components/cleanui/system/Auth/style.module.scss";
</style>
